import {
  Box,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { selectNotificationById } from "../../../../../store/features/notificationsSlice";
import ActionsButton from "./components/ActionsButton";
import BodyContent from "./components/BodyContent";
import CustomAvatar from "./components/CustomAvatar";
import SentAt from "./components/SentAt";

function NotificationItem({ notificationId, onClick = () => {} }) {
  const [hovered, setHovered] = useState(false);

  const data = useSelector((state) =>
    selectNotificationById(state, notificationId)
  );

  const { is_read, title, body, variant, payload, sent_at, id } = data;

  return (
    <ListItemButton
      onClick={() => onClick(id, variant, payload)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      disableRipple
      sx={{
        alignItems: "flex-start",
        transition: "none",
        cursor: variant === "system" ? "default" : "pointer",
        opacity: is_read ? 0.9 : 1,
      }}
    >
      {variant !== "system" && (
        <CustomAvatar variant={variant} payload={payload} />
      )}
      <ListItemText
        sx={{ ml: -1 }}
        slotProps={{
          primary: {
            variant: "body1",
            fontSize: "1rem",
            fontWeight: "600",
            component: "div",
          },
          secondary: {
            component: "div",
            fontWeight: "500",
            color: "textSecondary",
            fontSize: ".875rem",
            sx: {
              wordBreak: "break-word",
            },
          },
        }}
        secondary={
          <>
            <BodyContent variant={variant} body={body} />
            <Stack width={"100%"} direction={"row"} justifyContent={"flex-end"}>
              <ActionsButton hovered={hovered} notificationId={id} />
            </Stack>
          </>
        }
        primary={
          <Stack direction={"row"} alignItems={"flex-start"}>
            <Box pr={2} flex={1}>
              <Typography fontWeight={"600"} fontSize={"1rem"}>
                {title}
              </Typography>
            </Box>
            <Stack alignItems={"flex-end"} justifyContent={"space-between"}>
              <SentAt sentAt={sent_at} isRead={is_read} />
            </Stack>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

export default memo(NotificationItem);
