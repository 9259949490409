import { NotificationsActiveOutlined } from "@mui/icons-material";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchNotifications,
  readNotifications,
  resetUnreadNotificationsTotal,
  selectNotificationsIds,
  selectNotificationsTotal,
  selectUnreadNotificationsNum,
} from "../../../../store/features/notificationsSlice";
import NoDataContent from "../../../form/NoDataContent";
import Layout from "./Layout";
import NotificationButton from "./NotificationButton";
import NotificationsMenu from "./NotificationsMenu";
import NotificationItem from "./notification-item/NotificationItem";

function NotificationsMain(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [menu, setMenu] = useState(null);

  const onClose = useCallback(() => {
    setMenu(null);
  }, []);

  const opened = useMemo(() => Boolean(menu), [menu]);

  const [filter, setFilter] = useState("all");

  const loading = useSelector((state) => state.notifications.loading);
  const notificationsTotal = useSelector(selectNotificationsTotal);
  const unreadNum = useSelector(selectUnreadNotificationsNum);
  const notificationsIds = useSelector(selectNotificationsIds);

  const fetcher = useCallback(() => {
    dispatch(fetchNotifications());
  }, []);

  useEffect(() => {
    if (opened) {
      fetcher();
      dispatch(resetUnreadNotificationsTotal());
    } else {
      dispatch(readNotifications());
    }
  }, [opened]);

  useEffect(() => {
    if (unreadNum > 0 && opened) {
      fetcher();
    }
  }, [unreadNum]);

  const handleChangeFilter = useCallback((value) => {
    setFilter(value);
  }, []);

  const handleNotificationClick = useCallback((id, variant, payload = {}) => {
    if (
      [
        "advance-approved",
        "request-approved",
        "advance-rejected",
        "request-rejected",
        "report-finished",
        "advance-paid",
      ].includes(variant)
    ) {
      navigate(`/approval-requests/${payload?.request_id}`);
      onClose();
      return;
    }
    if (["advance-to-approve", "request-to-approve"].includes(variant)) {
      navigate(`/approver/${payload?.request_id}`);
      onClose();
      return;
    }
  }, []);

  const renderNotifications = useCallback((notificationId) => {
    return (
      <NotificationItem
        key={notificationId}
        notificationId={notificationId}
        onClick={handleNotificationClick}
      />
    );
  }, []);

  return (
    <>
      <NotificationButton
        unreadNum={unreadNum}
        opened={opened}
        onClick={(e) => setMenu(e.target)}
      />
      {opened && (
        <NotificationsMenu anchorEl={menu} onClose={onClose}>
          <Layout
            filter={filter}
            onChangeFilter={handleChangeFilter}
            loading={loading}
            num={notificationsTotal}
            onClose={onClose}
          >
            {!Boolean(notificationsTotal) ? (
              <NoDataContent
                iconSize="6rem"
                titleSize="1.2rem"
                Icon={NotificationsActiveOutlined}
                title={"Nada por aqui"}
                subtitle={"Volte mais tarde para ver novidades"}
              />
            ) : (
              notificationsIds.map(renderNotifications)
            )}
          </Layout>
        </NotificationsMenu>
      )}
    </>
  );
}

export default memo(NotificationsMain);
