import { LocalGasStation, Speed } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
} from "@mui/material";
import React, { memo, useCallback } from "react";
import Baseline from "../../../../components/form/Baseline";
import SelectInput from "../../../../components/inputs/Select";
import TextInput from "../../../../components/inputs/text-input";
import formatAmount from "../../../../utils/formatAmount";
import { curr } from "../../../../utils/more/currency_country";
import VehiclesInput from "../../components/inputs/VehiclesInput";
import SelectorBox from "../../components/SelectorBox";

const fuelTypes = {
  gasoline: "Gasolina",
  diesel: "Diesel",
  ethanol: "Etanol",
};

const VehicleInfo = ({
  onChangeValue = () => {},
  values = {},
  variant,
  isEditable,
  disabled,
  role,
  margin,
  currency,
  disableKmlInfo,
}) => {
  const { vehicle, fuelType, odometer, liters, kml, pricePerLiter, fullTank } =
    values || {};

  const handleChangeVehicle = useCallback(
    (v) => onChangeValue("vehicle", v),
    []
  );

  return (
    <Box>
      <Stack>
        {isEditable ? (
          <SelectorBox Icon={LocalGasStation}>
            <SelectInput
              label={"Tipo do combustível"}
              size={"small"}
              margin={margin}
              variant={variant}
              value={fuelType || ""}
              onChange={(v) => onChangeValue("fuelType", v)}
            >
              <MenuItem value="gasoline">Gasolina</MenuItem>
              <MenuItem value="diesel">Diesel</MenuItem>
              <MenuItem value="ethanol">Etanol</MenuItem>
            </SelectInput>
          </SelectorBox>
        ) : (
          <Baseline
            sx={{ mb: 2 }}
            Icon={LocalGasStation}
            label="Tipo do combustível"
            emptyLabel={"Não definido"}
            value={fuelTypes[fuelType] || ""}
          />
        )}
        <Stack
          mt={isEditable ? -0.5 : 0}
          gap={1}
          direction={"row"}
          alignItems={"center"}
          mb={isEditable ? 0 : 2}
        >
          {isEditable ? (
            <SelectorBox Icon={Speed}>
              <TextInput
                size={"small"}
                margin={"normal"}
                variant={variant}
                label={"Quilometragem"}
                type="number"
                readOnly={!isEditable}
                value={odometer}
                RightIcon={() => "km"}
                onChange={(v) => onChangeValue("odometer", v)}
              />
            </SelectorBox>
          ) : (
            <Baseline
              sx={{ width: 150 }}
              Icon={Speed}
              label="Quilometragem"
              emptyLabel={"Não informado"}
              value={!odometer ? "" : `${odometer?.toLocaleString("pt-BR")} km`}
            />
          )}
          {isEditable ? (
            <TextInput
              width={150}
              readOnly={!isEditable}
              size={"small"}
              type="number"
              variant={variant}
              value={liters}
              onChange={(v) => onChangeValue("liters", v)}
              margin={"normal"}
              label={"Litros"}
            />
          ) : (
            <Baseline
              label={`Litros (${
                fullTank ? "Tanque cheio" : "Tanque incompleto"
              })`}
              emptyLabel={"Não informado"}
              value={!liters ? "" : parseFloat(liters)?.toLocaleString("pt-BR")}
            />
          )}
        </Stack>
        {isEditable && (
          <Box pl={6}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={Boolean(fullTank)}
                  onClick={() => onChangeValue("fullTank", !fullTank)}
                />
              }
              label="Tanque cheio"
            />
          </Box>
        )}
        {(Boolean(kml) || Boolean(pricePerLiter)) &&
          role !== "personal" &&
          !disableKmlInfo && (
            <Stack
              mt={isEditable ? 0.5 : 0}
              mb={2}
              direction={"row"}
              alignItems={"center"}
              gap={0.5}
              fontSize={".9rem"}
              fontWeight={"600"}
              color={"text.secondary"}
            >
              <Baseline
                sx={{ width: 150 }}
                Icon={() => {}}
                iconSx={{ color: "primary.main" }}
                label="Preço por LT"
                emptyLabel={"--"}
                value={
                  pricePerLiter
                    ? `${curr(currency)} ${formatAmount(pricePerLiter || 0)}`
                    : ""
                }
              />
              <Baseline
                label="Média"
                emptyLabel={"--"}
                value={kml ? `${parseFloat(kml).toFixed(2)} km/l` : ""}
              />
            </Stack>
          )}
      </Stack>

      <VehiclesInput
        isEditable={isEditable}
        readOnly={disabled}
        variant={variant}
        value={vehicle}
        onChange={handleChangeVehicle}
        role={role}
        margin={"dense"}
      />
    </Box>
  );
};

export default memo(VehicleInfo);
