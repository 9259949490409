import {
  DeleteOutlineOutlined,
  MoreVert,
  Rule,
  SendOutlined,
} from "@mui/icons-material";
import { IconButton, MenuItem, Popover } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import EditExpensesStatusWrapper from "../../../../../../actions/expenses/EditExpensesStatusWrapper";
import RemoveExpensesWrapper from "../../../../../../actions/expenses/RemoveExpensesWrapper";
import AcctValuesModal from "./AcctValuesModal";
import CopyModel from "./CopyModel";
import SendConfirmationWrapper from "./SendConfirmationWrapper";

const MoreButton = ({
  isEditable,
  expenseId,
  role,
  originalValuesRef,
  onSendToApproval,
  disabled,
  onClose,
  onRefresh = () => {},
}) => {
  const [menu, setMenu] = useState(null);
  const closeMenu = useCallback(() => setMenu(null), []);
  return (
    <>
      <IconButton onClick={(e) => setMenu(e.target)}>
        <MoreVert />
      </IconButton>
      <Popover
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={closeMenu}
        slotProps={{
          paper: {
            sx: {
              py: 1,
            },
          },
        }}
        sx={{
          "& .MuiMenuItem-root": {
            height: 50,
            fontSize: ".96rem",
            fontWeight: "600",
          },
        }}
      >
        {isEditable && role === "personal" && (
          <SendConfirmationWrapper
            expensesIds={[expenseId]}
            onSendToApproval={onSendToApproval}
            renderComponent={({ openModal }) => (
              <MenuItem disabled={disabled} color="primary" onClick={openModal}>
                <SendOutlined sx={{ mr: 2 }} color="action" />
                Enviar para aprovação
              </MenuItem>
            )}
          />
        )}
        <CopyModel
          disabled={disabled}
          originalValuesRef={originalValuesRef}
          closeMenu={closeMenu}
        />
        {["financial", "director"].includes(role) && (
          <EditExpensesStatusWrapper
            role={role}
            onSuccess={onRefresh}
            renderComponent={({ openModal }) => (
              <MenuItem
                disabled={disabled}
                onClick={() => openModal([expenseId])}
              >
                <Rule sx={{ mr: 2 }} color="action" />
                Alterar status
              </MenuItem>
            )}
          />
        )}
        {["financial", "director"].includes(role) && (
          <AcctValuesModal
            entityId={expenseId}
            originalValuesRef={originalValuesRef}
            type="expenses"
          />
        )}
        {(isEditable || ["financial", "director"].includes(role)) && (
          <RemoveExpensesWrapper
            onClose={onClose}
            role={role}
            renderComponent={({ openModal }) => (
              <MenuItem
                disabled={disabled}
                onClick={() => openModal([expenseId])}
              >
                <DeleteOutlineOutlined sx={{ mr: 2 }} color="action" />
                Excluir
              </MenuItem>
            )}
          />
        )}
      </Popover>
    </>
  );
};

export default memo(MoreButton);
