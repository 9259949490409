import { Box, LinearProgress, List, Stack } from "@mui/material";
import React from "react";
import LoadingSection from "../../../loading/LoadingSection";
import NotificationsHeader from "./NotificationsHeader";

function Layout({ num, children, loading, onClose, filter, onChangeFilter }) {
  return (
    <>
      <NotificationsHeader loading={loading} onClose={onClose} />
      <Stack height={"100%"} p={2}>
        <List
          sx={{
            borderRadius: 5,
            bgcolor: "background.default",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            position: "relative",
          }}
        >
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              height: loading && num > 0 ? 2 : 0,
              transition: ".2s ease",
              width: "100%",
            }}
          />
          {loading && num === 0 ? (
            <LoadingSection />
          ) : (
            <Box width={"100%"} flex={1} flexBasis={0} overflow={"auto"}>
              {children}
            </Box>
          )}
        </List>
      </Stack>
    </>
  );
}

export default Layout;
