import { Box } from "@mui/material";
import React, { memo } from "react";

const BodyContent = ({ body, variant, payload = {} }) => {
  return (
    <>
      <Box
        sx={{ wordBreak: "break-word" }}
        component={"div"}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </>
  );
};

export default memo(BodyContent);
