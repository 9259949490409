import { Box, Link, Popover, Stack, Typography, lighten } from "@mui/material";
import React from "react";

import { Circle } from "@mui/icons-material";
import pckg from "../../../../package.json";
import "./styles.css";

function MenuContainer({ anchorEl, onClose, children, name }) {
  return (
    <Popover
      elevation={5}
      sx={{ mt: 1 }}
      disableScrollLock
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      transitionDuration={0}
      TransitionProps={{ unmountOnExit: true }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      slotProps={{
        paper: {
          className: "account-menu",
          elevation: 0,
          sx: {
            boxShadow: 5,
            width: 410,
            borderRadius: 6,
            bgcolor: (t) =>
              t.palette.mode === "light"
                ? lighten(t.palette.primary.main, 0.95)
                : lighten(t.palette.background.default, 0.015),
          },
        },
      }}
    >
      <Box p={2}>{children}</Box>
      <Stack
        width={"100%"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        pb={2}
        gap={1}
      >
        <Typography
          textAlign={"center"}
          color="text.secondary"
          variant="caption"
        >
          Versão {pckg.version}
        </Typography>
        <Circle sx={{ fontSize: ".24rem" }} color="action" />
        <Link
          href="https://www.notepay.com/legal/privacy"
          target="_blank"
          textAlign={"center"}
          color="text.secondary"
          variant="caption"
        >
          Política de privacidade
        </Link>
        <Circle sx={{ fontSize: ".24rem" }} color="action" />
        <Link
          href="https://www.notepay.com/legal/terms"
          target="_blank"
          textAlign={"center"}
          color="text.secondary"
          variant="caption"
        >
          Termos de uso
        </Link>
      </Stack>
    </Popover>
  );
}

export default MenuContainer;
