import { AccountBalanceOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  LinearProgress,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { clearCache } from "../../../../../../api/cache";
import { HttpClient } from "../../../../../../api/httpClient";
import TextInput from "../../../../../../components/inputs/text-input";
import { updateAdvance } from "../../../../../../store/features/advancesSlice";
import { setError } from "../../../../../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../../../../../store/features/base/snackbarBaseSlice";
import { updateExpense } from "../../../../../../store/features/expensesSlice";
import formatAmount from "../../../../../../utils/formatAmount";
import { curr } from "../../../../../../utils/more/currency_country";
import CloseableDialogTitle from "../../../../../CloseableDialogTitle";

const CustomModal = ({
  open,
  onClose,
  originalValuesRef,
  entityId,
  type = "expenses",
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [creditAcctCode, setCreditAcctCode] = useState(
    originalValuesRef?.current?.ledger?.creditAcct?.code || ""
  );
  const [creditAcctText, setCreditAcctText] = useState(
    originalValuesRef?.current?.ledger?.creditAcct?.text || ""
  );
  const [debitAcctCode, setDebitAcctCode] = useState(
    originalValuesRef?.current?.ledger?.debitAcct?.code || ""
  );
  const [debitAcctText, setDebitAcctText] = useState(
    originalValuesRef?.current?.ledger?.debitAcct?.text || ""
  );

  const amount = useMemo(
    () =>
      (type === "expenses"
        ? originalValuesRef?.current?.displayAmount?.amount
        : originalValuesRef?.current?.amount) || 0,
    [originalValuesRef]
  );
  const currency = useMemo(
    () =>
      (type === "expenses"
        ? originalValuesRef?.current?.displayAmount?.currency
        : originalValuesRef?.current?.currency) || "",
    [originalValuesRef]
  );

  const abortControllerRef = useRef(null);

  const handleClose = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    onClose();
  };

  const handleSave = async () => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const newLedger = {
      creditAcct:
        creditAcctCode || creditAcctText
          ? {
              code: creditAcctCode || undefined,
              text: creditAcctText || undefined,
            }
          : undefined,
      debitAcct:
        debitAcctCode || debitAcctText
          ? {
              code: debitAcctCode || undefined,
              text: debitAcctText || undefined,
            }
          : undefined,
    };

    setLoading(true);
    if (type === "expenses") {
      try {
        const { data: responseData } = await HttpClient.put({
          url: `/expenses/${entityId}`,
          body: {
            ledger: newLedger,
          },
          params: {
            role: "financial",
          },
          signal: abortController.signal,
        });
        dispatch(openSnackbar({ message: "Dados contábeis atualizados" }));
        dispatch(
          updateExpense({
            role: "financial",
            id: entityId,
            changes: responseData,
          })
        );
        clearCache("/expenses");
        originalValuesRef.current = {
          ...(originalValuesRef.current || {}),
          ...responseData,
        };

        onClose();
      } catch (error) {
        dispatch(setError({ error }));
      }
    } else if (type === "advances") {
      try {
        const { data: responseData } = await HttpClient.put({
          url: `/advances/${entityId}`,
          body: {
            ledger: newLedger,
          },
          params: {
            role: "financial",
          },
          signal: abortController.signal,
        });
        clearCache("/advances");
        dispatch(openSnackbar({ message: "Dados contábeis atualizados" }));

        originalValuesRef.current = {
          ...(originalValuesRef.current || {}),
          ...responseData,
        };
        dispatch(
          updateAdvance({
            role: "financial",
            id: entityId,
            changes: responseData,
          })
        );
        onClose();
      } catch (error) {
        dispatch(setError({ error }));
      }
    }
    setLoading(false);
  };

  return (
    <Dialog maxWidth="xs" open={open} fullWidth>
      {loading && <LinearProgress />}
      <CloseableDialogTitle sx={{ fontSize: "1.3rem" }} onClose={handleClose}>
        <AccountBalanceOutlined color="primary" sx={{ mr: 2 }} />
        Dados contábeis
      </CloseableDialogTitle>
      <DialogContent sx={{ pb: 5 }}>
        <DialogContentText>
          Informações das contas contábeis da despesa, abrangendo crédito e
          débito.
        </DialogContentText>
        <Stack mt={2} direction={"row"} alignItems={"center"}>
          <Typography fontSize={"1.2rem"} fontWeight={"600"}>
            Conta de crédito
          </Typography>
          <Box flex={1} />
          <Typography
            fontSize={"1.2rem"}
            fontWeight={"600"}
            color="textSecondary"
          >
            - {curr(currency)} {formatAmount(amount)}
          </Typography>
        </Stack>
        <Stack>
          <TextInput
            margin={"normal"}
            size={"small"}
            variant={"standard"}
            label={"Código"}
            value={creditAcctCode}
            onChange={setCreditAcctCode}
          />
          <TextInput
            margin={"dense"}
            size={"small"}
            variant={"standard"}
            multiline
            label={"Descrição"}
            value={creditAcctText}
            onChange={setCreditAcctText}
          />
        </Stack>
        <Stack mt={3} direction={"row"} alignItems={"center"}>
          <Typography fontSize={"1.2rem"} fontWeight={"600"}>
            Conta de débito
          </Typography>
          <Box flex={1} />
          <Typography fontSize={"1.2rem"} fontWeight={"600"} color="primary">
            + {curr(currency)} {formatAmount(amount)}
          </Typography>
        </Stack>
        <Stack>
          <TextInput
            margin={"normal"}
            size={"small"}
            variant={"standard"}
            label={"Código"}
            value={debitAcctCode}
            onChange={setDebitAcctCode}
          />
          <TextInput
            margin={"dense"}
            size={"small"}
            variant={"standard"}
            multiline
            label={"Descrição"}
            value={debitAcctText}
            onChange={setDebitAcctText}
          />
        </Stack>
        <Stack mt={3} direction={"row"} alignItems={"center"}>
          <Box flex={1} />
          <Typography
            fontSize={"1.2rem"}
            fontWeight={"600"}
            color="textSecondary"
          >
            = R$ 0,00
          </Typography>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          onClick={handleSave}
          disabled={loading}
          variant="contained"
          disableElevation
        >
          {loading ? "Salvando" : "Salvar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AcctValuesModal = ({
  originalValuesRef,
  type = "expenses",
  entityId,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {type === "expenses" ? (
        <MenuItem onClick={() => setOpen(true)}>
          <AccountBalanceOutlined sx={{ mr: 2 }} color="action" />
          Dados contábeis
        </MenuItem>
      ) : (
        <Button
          startIcon={<AccountBalanceOutlined />}
          onClick={() => setOpen(true)}
          sx={{ height: 35, mr: 2, px: 1 }}
          variant="text"
        >
          Dados contábeis
        </Button>
      )}

      {open && (
        <CustomModal
          open={open}
          onClose={() => setOpen(false)}
          originalValuesRef={originalValuesRef}
          entityId={entityId}
          type={type}
        />
      )}
    </>
  );
};

export default memo(AcctValuesModal);
