export const getAccountStatusData = (
  status,
  metadata = {
    plan: "",
  }
) => {
  let data = {
    title: "",
    small_title: "Nenhum plano selecionado",
    description: "Nenhum plano selecionado",
    severity: "info",
  };

  switch (status) {
    case "incomplete":
      data.title = "A primeira tentativa de pagamento falhou";
      data.small_title = "A primeira tentativa de pagamento falhou";
      data.description = "";
      data.severity = "warning";
      break;
    case "incomplete_expired":
      data.title =
        "Sua conta foi inativada devido a problemas no processamento do pagamento da primeira fatura.";
      data.small_title = "Conta inativada";
      data.description =
        "Sua conta foi temporariamente desativada devido a dificuldades no processamento do pagamento da primeira fatura. Por favor, verifique suas informações de pagamento.";
      data.severity = "error";
      break;
    case "past_due":
      data.title = "Aguardando pagamento, verifique sua fatura em aberto.";
      data.small_title = "Pagamento pendente";
      data.description = "";
      data.severity = "warning";
      break;
    case "active":
      data.title = "Sua conta NotePay está ativa.";
      data.small_title = "Sua conta NotePay está ativa.";
      data.description =
        "Sua conta NotePay está funcionando normalmente. Continue utilizando dos nossos serviços.";
      data.severity = "success";
      break;
    case "trialing":
      data.title = "Sua conta NotePay está no período de avaliação gratuita.";
      data.small_title = "Período de avaliação gratuita";
      data.description = metadata?.plan
        ? `Você está aproveitando um período de avaliação gratuita. Explore todos os recursos do plano ${
            metadata?.plan || "básico"
          } sem nenhum custo.`
        : `Você está aproveitando um período de avaliação gratuita. Explore todos os recursos básicos da NotePay sem nenhum custo.`;
      data.severity = "info";
      break;
    case "unpaid":
      data.title =
        "Sua conta foi inativada devido a problemas no processamento do pagamento da fatura.";
      data.small_title = "Conta inativada";
      data.description =
        "Sua conta foi temporariamente desativada devido a dificuldades no processamento do pagamento da fatura. Por favor, verifique suas informações de pagamento.";
      data.severity = "error";
      break;
    case "canceled":
      data.title =
        "Sua conta está inativa. Seu plano foi cancelado, verifique seu faturamento.";
      data.small_title = "Plano cancelado";
      data.description =
        "Sua conta foi cancelada e está atualmente inativa. Verifique o status do seu faturamento para reativar sua conta, se necessário.";
      data.severity = "error";
      break;
    default:
      break;
  }
  return data;
};

export const accountStatusIsError = (status = "") =>
  getAccountStatusData(status)?.severity === "error";
