import { formatTableColumnVisibilityMigration } from "./utils";

export const root_migrations = {
  7: (state) => {
    return {
      ...state,
      account: {
        ...state.account,
        baseInfo: {
          ...state.account?.baseInfo,
          roles: {},
        },
      },
      settings: {
        ...state.settings,
        isFirstAccess: true,
        helpers: {
          completed: [],
          activeHelperId: null,
        },
        persistedTables: {
          ...state.settings.persistedTables,
          ...formatTableColumnVisibilityMigration(state, "exp_grid", {
            formattedRate: false,
            sentAt: false,
            placeName: false,
            projectName: false,
            from: false,
            to: false,
            distance: false,
            formattedRate: false,
            sapDocument: false,
            sentAt: false,
            rejectedAt: false,
            approvedAt: false,
            finishedAt: false,
            kml: false,
            fuelType: false,
            vehicle: false,
            liters: false,
            pricePerLiter: false,
            odometer: false,
            city: false,
            state: false,
            country: false,
            destination: false,
            company: false,
            costcenter: false,
            creditAcct: false,
            debitAcct: false,
            dailyRate: false,
            dailyNumber: false,
          }),
        },
      },
    };
  },
  9: (state) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        isFirstAccess: false,
        persistedTables: {
          ...state.settings.persistedTables,
          ...formatTableColumnVisibilityMigration(
            state?.settings?.persistedTables,
            "exp_grid",
            {
              createdAt: false,
              branchCode: false,
              plant: false,
            }
          ),
          ...formatTableColumnVisibilityMigration(
            state?.settings?.persistedTables,
            "adv_grid",
            {
              company: false,
              branchCode: false,
              plant: false,
              costcenter: false,
              creditAcct: false,
              debitAcct: false,
            }
          ),
        },
      },
    };
  },
};
