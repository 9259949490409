import { AutoAwesome, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DropzoneReceipt from "../../components/dropzone/dropzone-receipt/DropzoneReceipt";

import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import useReceiptsUpload from "../../hooks/useReceiptsUpload";
import {
  closeElement,
  selectModalIsOpened,
} from "../../store/features/base/modalsSlice";
import { addScanningReceipts } from "../../store/features/expensesSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} {...props} direction="up" />;
});

const ModalExpensesScanner = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const open = useSelector((state) =>
    selectModalIsOpened(state, "modalExpensesScanner")
  );

  const onClose = useCallback(
    () => dispatch(closeElement("modalExpensesScanner")),
    []
  );

  const { receipts, onRemoveReceipt, onUploadFiles, setReceipts } =
    useReceiptsUpload(true);

  useEffect(() => {
    if (!open) {
      setReceipts([]);
    }
  }, [open]);

  const handleAddFiles = () => {
    navigate(`/expenses/personal?expensesFilter=opened`);
    const formattedReceipts = receipts.map((receipt) => ({
      ...receipt,
      expenseId: nanoid(6),
      createdAt: new Date().toISOString(),
      status: "idle",
    }));
    dispatch(addScanningReceipts(formattedReceipts));
    onClose();
  };

  return (
    <Dialog
      sx={{ p: 3, pb: 0 }}
      PaperProps={{
        sx: {
          height: "100%",
          maxWidth: 620,
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      }}
      TransitionComponent={Transition}
      fullScreen
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle fontWeight={"600"} fontSize={"1.5rem"}>
        <AutoAwesome color="primary" sx={{ mr: 2 }} />
        <Typography
          sx={{
            background: "linear-gradient(45deg, #1D72E8, #0F3974)",
            WebkitBackgroundClip: "text",
            color: "transparent",
          }}
          variant="inherit"
        >
          Leitor inteligente de notinhas
        </Typography>
        <Box flex={1} />
        <IconButton onClick={onClose} sx={{ m: -1 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers={Boolean(receipts?.length)}
        sx={{ display: "flex", flexDirection: "column", p: 0 }}
      >
        {!Boolean(receipts?.length) && (
          <DialogContentText m={3} mt={0} mb={1} fontSize={"1rem"}>
            Envie suas notinhas e relaxe com um café. <br /> Nós cuidaremos das
            suas despesas.
          </DialogContentText>
        )}
        <DropzoneReceipt
          enableMultipleReceipts
          files={receipts}
          onUploadFiles={onUploadFiles}
          onRemove={onRemoveReceipt}
          isEditable
          gridOptions={{
            cols: 3,
          }}
        />
      </DialogContent>
      {Boolean(receipts?.length) && (
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            onClick={handleAddFiles}
            disabled={!Boolean(receipts.length)}
            autoFocus
            variant="contained"
            disableElevation
          >
            Ler notinha{receipts?.length > 1 ? "s" : ""}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default memo(ModalExpensesScanner);
