import React, { Suspense, lazy, useCallback, useMemo } from "react";
import "../App.css";

import { Box, lighten } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Fallback from "../components/Fallback";
import Appbar from "../components/appbar/Appbar";
import BottomBoxes from "../components/bottom-boxes";
import Sidebar from "../components/sidebar/Sidebar";
import SidebarContentWrapper from "../components/sidebar/sidebar-content/SidebarContentWrapper";
import ErrorBoundary from "../errors/Boundary";
import ModalNewExpense from "../modals/expenses/new/ModalNewExpense";
import ModalExpenseView from "../modals/expenses/view/ModalExpenseView";
import ModalCreateReport from "../modals/reports/create-report/ModalCreateReport";
import ModalNewRoute from "../modals/route/new/ModalNewRoute";
import ModalExpensesScanner from "../modals/scanner-expenses/ModalExpensesScanner";
import { selectUserBaseInfo } from "../store/features/accountSlice";
import { ADMIN_ROUTES } from "./admin_routes";
import BoxContent from "./components/BoxContent";

const FreeTrialStripe = lazy(() => import("../components/free-trial-stripe"));

const ExpensesWrapper = lazy(() => import("../pages/expenses/ExpensesWrapper"));
const ReportsWrapper = lazy(() => import("../pages/reports/ReportsWrapper"));
const AdvancesWrapper = lazy(() => import("../pages/advances/AdvancesWrapper"));
const TransactionsWrapper = lazy(() =>
  import("../pages/transactions/TransactionsWrapper")
);
const Insights = lazy(() => import("../pages/insights/index"));
const ChartsHome = lazy(() =>
  import("../pages/insights/pages/home/ChartsHome")
);
const ChartsByTotalAmount = lazy(() =>
  import("../pages/insights/pages/ChartsByTotalAmount")
);

const Settings = lazy(() => import("../pages/settings/index"));

const Billing = lazy(() => import("../pages/billing/BillingPage"));
const Invoices = lazy(() => import("../pages/billing/invoices/InvoicesPage"));

const DEFAULT_ROLE = localStorage.getItem("default_role_view") || "personal";

export default (props) => {
  const { account } = useSelector(selectUserBaseInfo);

  const showStripeInfo = useMemo(
    () =>
      account?.status === "trialing" ||
      account?.subscriptionPlan === "trial" ||
      account?.status === "canceled",
    [account?.subscriptionPlan, account?.status]
  );

  //ADMIN PAGES
  const renderAdminRoutes3 = useCallback((c3) => {
    return (
      <Route key={c3.path} path={c3.path} element={c3.wrapper}>
        {(c3.element || c3.indexElement) && (
          <Route index element={c3.element || c3.indexElement} />
        )}
        <Route path={c3.path} element={c3.element} />
      </Route>
    );
  }, []);

  const renderAdminRoutes2 = useCallback((c2) => {
    return (
      <Route key={c2.path} path={c2.path} element={c2.wrapper}>
        {(c2.element || c2.indexElement) && (
          <Route index element={c2.element || c2.indexElement} />
        )}
        {c2.children && c2.children.map(renderAdminRoutes3)}
      </Route>
    );
  }, []);

  const renderAdminRoutes1 = useCallback((c1) => {
    return (
      <Route key={c1.path} path={c1.path} element={c1.wrapper}>
        {(c1.element || c1.indexElement) && (
          <Route index element={c1.element || c1.indexElement} />
        )}
        {c1.children.map(renderAdminRoutes2)}
      </Route>
    );
  }, []);

  return (
    <ErrorBoundary>
      <Box flex={1} display={"flex"} flexDirection={"column"}>
        <Suspense>{showStripeInfo && <FreeTrialStripe />}</Suspense>
        <Box component={"div"} className="grid_container">
          <Box
            component={"nav"}
            className="sidebar_main"
            height={"100%"}
            width={"5.2em"}
            //#E9F1FB
            bgcolor={(t) => lighten(t.palette.primary.main, 0.9)}
          >
            <Sidebar />
          </Box>
          <Box width={"100%"} className="appbar_container">
            <Appbar />
          </Box>
          <Box component={"div"} className="sidebar_container">
            <SidebarContentWrapper />
          </Box>
          <Box
            component={"div"}
            className="routes_container"
            width={"100%"}
            height={"100%"}
            p={2}
            pt={0}
          >
            <BoxContent>
              <ErrorBoundary>
                <AnimatePresence mode="popLayout">
                  <Suspense fallback={<Fallback />}>
                    <Routes>
                      <Route
                        index
                        element={<Navigate to={"expenses"} replace />}
                      />
                      <Route path="expenses">
                        <Route
                          index
                          element={<Navigate to={DEFAULT_ROLE} replace />}
                        />
                        <Route path=":role" element={<ExpensesWrapper />} />
                      </Route>
                      <Route path="reports">
                        <Route
                          index
                          element={<Navigate to={DEFAULT_ROLE} replace />}
                        />
                        <Route path=":role" element={<ReportsWrapper />} />
                      </Route>
                      <Route path="advances">
                        <Route
                          index
                          element={<Navigate to={DEFAULT_ROLE} replace />}
                        />
                        <Route path=":role" element={<AdvancesWrapper />} />
                      </Route>
                      <Route path="transactions">
                        <Route index element={<TransactionsWrapper />} />
                      </Route>
                      <Route path="insights" element={<Insights />}>
                        <Route
                          index
                          element={<Navigate to={"overview"} replace />}
                        />
                        <Route path="overview" element={<ChartsHome />} />
                        <Route
                          path="totalAmount"
                          element={<ChartsByTotalAmount />}
                        />
                        <Route path="totalFuel" element={<>totalFuel</>} />
                      </Route>
                      <Route path="admin">
                        <Route
                          index
                          element={<Navigate to={"branches"} replace />}
                        />
                        {ADMIN_ROUTES.map(renderAdminRoutes1)}
                      </Route>
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/billing" element={<Billing />} />
                      <Route path="/invoices" element={<Invoices />} />
                    </Routes>
                  </Suspense>
                </AnimatePresence>
              </ErrorBoundary>
            </BoxContent>
          </Box>
          {/* {pathname.includes("insights/overview") && <FilterMenu />} */}
        </Box>
      </Box>
      <ModalCreateReport />
      <ModalExpensesScanner />
      <BottomBoxes />

      <ModalNewRoute />
      <ModalNewExpense />
      <ModalExpenseView />
    </ErrorBoundary>
  );
};
